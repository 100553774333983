import { RechercheBody } from '@/types/RechercheBody';
import axios from 'axios';

const recherche = {
  namespaced: true,
  actions: {
    saveRecherche(context, recherche: RechercheBody) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/saveRecherche`;
        axios
          .post(url, recherche)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            reject(message);
          });
      });
    },
  },
};

export default recherche;
