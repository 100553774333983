
import { defineComponent, provide, ref } from 'vue';
import FiltresEntreprises from '@/components/FiltresEntreprises.vue';
import FooterPartner from '@/components/FooterPartner.vue';
import { Filtre } from '@/types/Filtre';
import { Entreprise } from '@/types/Entreprise';
import { FiltreType } from '@/types/FiltreType';
import JsonCSV from 'vue-json-csv';
import RequeteRechercheHandler from '@/handlers/RequeteRechercheHandler';
import EventSynchronisationHandler from '@/handlers/EventSynchronisationHandler';
import { EventSynchronisationName } from '@/types/EventSynchronisationName';
import { EntrepriseProduits } from '@/types/EntrepriseProduits';
import { FiltresRegroupements } from '../types/FiltresRegroupements';
import ModalEntreprise from '@/components/ModalEntreprise.vue';

export default defineComponent({
  name: 'ListeEntreprises',
  components: {
    FiltresEntreprises,
    FooterPartner,
    ModalEntreprise,
    JsonCSV,
  },
  data() {
    return {
      listeEntreprisesAffiche: new Array<Entreprise>(),
      listeEntrepriseCache: new Array<Entreprise>(),
      indexPage: 0,
      numberOfPages: 0,
      nbEntreprisesParPage: 4,
      recherche: '',
      totalResultat: 0,
      entreprisesPretes: false,
      showModal: false,
    };
  },
  setup() {
    const filtres = ref(new Map<string, Filtre>());
    provide('filtres', filtres);
    return {
      filtres,
    };
  },
  methods: {
    openModalEntreprise(entreprise: Entreprise) {
      this.showModal = true;
      this.$refs.modalEntreprise.updateData(entreprise);
    },
    getFiltresActifsSelonEntrepriseOuProduit(): FiltresRegroupements {
      const filtresToReturn = new FiltresRegroupements();
      for (let filtre of this.filtres.values()) {
        if (filtre.estActif) {
          filtresToReturn.addFiltre(filtre);
        }
      }
      return filtresToReturn;
    },
    updateQueryParams() {
      this.$router.push({ query: { page: this.indexPage } });
    },
    nextPage() {
      if (this.indexPage < this.numberOfPages - 1) {
        this.indexPage++;
        this.updateQueryParams();
        this.chargeListeEntreprises();
      }
    },
    getNbOfFiltresActif() {
      let compteur = 0;
      for (let filter of this.filtres.values()) {
        if (filter.estActif) {
          compteur++;
        }
      }
      return compteur;
    },
    previousPage() {
      if (this.indexPage > 0) {
        this.indexPage--;
        this.updateQueryParams();
        this.chargeListeEntreprises();
      }
    },
    chargeListeEntreprises() {
      // récupération de la liste des entreprises
      const toutesEntreprises = this.getListeEntreprisesWithFilters();
      this.totalResultat = toutesEntreprises.length;
      this.listeEntreprisesAffiche =
        this.getEntrepriseListSelonPage(toutesEntreprises);
      this.numberOfPages = Math.ceil(toutesEntreprises.length / 4);
    },
    getListeEntreprisesWithFilters(): Array<Entreprise> {
      const listeEntreprises = this.listeEntrepriseCache;
      const filtresSelonType =
        this.getFiltresActifsSelonEntrepriseOuProduit() as FiltresRegroupements;

      // const filtresProduitsParTypes = new Map<FiltreType, Array<number>>();
      // filtresSelonType.filtresProduits.forEach((filtre) => {
      //   if (!filtresProduitsParTypes.has(filtre.type)) {
      //     filtresProduitsParTypes.set(filtre.type, new Array<number>());
      //   }
      //   filtresProduitsParTypes.get(filtre.type).push(filtre.id);
      // });
      const toutesEntreprises = listeEntreprises.filter((entreprise) => {
        return (
          this.entrepriseContienFiltre(entreprise, filtresSelonType) &&
          this.entrepriseContienRecherche(entreprise)
        );
      });
      return toutesEntreprises as Array<Entreprise>;
    },
    getFilterListAsString(filterList?: Array<Filtre>) {
      if (filterList) {
        return filterList.map((item) => item.nom).join(', ');
      }
      return null;
    },
    getListeEntreprisesToExport() {
      const entreprises = this.getListeEntreprisesWithFilters().map(
        (entreprise) => {
          const type_productions = this.getFilterListAsString(
            entreprise.type_productions
          );
          const type_clienteles = this.getFilterListAsString(
            entreprise.type_clienteles
          );
          const produits = this.getFilterListAsString(entreprise.produits);
          const newEntreprise = {
            ...entreprise,
            type_productions,
            type_clienteles,
            produits,
          };
          if (entreprise.ville) {
            newEntreprise.ville = entreprise.ville.nom;
          }
          return newEntreprise;
        }
      );
      return entreprises;
    },
    getEntrepriseListSelonPage(listeEntreprises: Array<Entreprise>) {
      const startIndex = this.indexPage * this.nbEntreprisesParPage;
      let endIndex = this.nbEntreprisesParPage;
      if (startIndex + endIndex >= listeEntreprises.length) {
        return listeEntreprises.slice(startIndex);
      } else {
        return listeEntreprises.slice(startIndex, startIndex + endIndex);
      }
    },
    checkIfFiltreHas(item: { id: number }, typeFiltre: FiltreType) {
      const filtreKey = typeFiltre + '-' + item.id;
      return this.filtres.get(filtreKey).estActif;
    },
    checkIfFiltreOfTypeHasActivatedItem(typeFiltre: FiltreType) {
      for (let filtre of this.filtres.values()) {
        if (filtre.type === typeFiltre && filtre.estActif) {
          return true;
        }
      }
      return false;
    },
    entrepriseContienFiltre(
      entreprise: Entreprise,
      filtresRegroupements: FiltresRegroupements
    ) {
      if (entreprise.entreprise_produits.length < 1) {
        return false;
      }
      const produitsValidesParId = new Map<number, Array<EntrepriseProduits>>();
      entreprise.entreprise_produits.forEach((produit_entreprise) => {
        let produitAUnFiltre =
          filtresRegroupements.filtresProduits.filtreOu.size === 0;
        for (let [filtreType, listFiltres] of filtresRegroupements
          .filtresProduits.filtreOu) {
          let produitFiltreId;
          let isArray = false;
          switch (filtreType) {
            case FiltreType.volume:
              produitFiltreId = produit_entreprise.volume_id;
              break;
            case FiltreType.produit:
              produitFiltreId = produit_entreprise.produit_id;
              break;
            case FiltreType.conservation:
              produitFiltreId = produit_entreprise.conservations;
              isArray = true;
              break;
            case FiltreType.specificite:
              produitFiltreId = produit_entreprise.specificites;
              isArray = true;
              break;
          }
          if (isArray) {
            if (
              listFiltres.some((filtre) =>
                produitFiltreId.some((item) => item.id === filtre.id)
              )
            ) {
              produitAUnFiltre = true;
            }
          } else {
            if (listFiltres.some((filtre) => filtre.id === produitFiltreId)) {
              produitAUnFiltre = true;
            }
          }
        }
        if (produitAUnFiltre) {
          if (!produitsValidesParId.has(produit_entreprise.produit_id)) {
            produitsValidesParId.set(
              produit_entreprise.produit_id,
              new Array<EntrepriseProduits>(produit_entreprise)
            );
          } else {
            produitsValidesParId
              .get(produit_entreprise.produit_id)
              .push(produit_entreprise);
          }
        }
      });
      let unProduitAToutLesFiltres = false;
      for (let [idProduit, produitsList] of produitsValidesParId) {
        if (
          filtresRegroupements.filtresProduits.filtreEt.size === 0 ||
          (filtresRegroupements.filtresProduits.filtreEt.has(
            FiltreType.typeTransaction
          ) &&
            filtresRegroupements.filtresProduits.filtreEt
              .get(FiltreType.typeTransaction)
              .every((filtre) =>
                produitsList.some(
                  (produit_entreprise) =>
                    produit_entreprise.type_transaction_id === filtre.id
                )
              ))
        ) {
          unProduitAToutLesFiltres = true;
          break;
        }
      }

      if (
        !unProduitAToutLesFiltres &&
        (filtresRegroupements.filtresProduits.filtreOu.size > 0 ||
          filtresRegroupements.filtresProduits.filtreEt.size > 0)
      ) {
        return false;
      }
      for (let [filtreType, filtres] of filtresRegroupements.filtresEntreprises
        .filtreOu) {
        let entrepriseFiltre;
        switch (filtreType) {
          case FiltreType.typeClientelle:
            entrepriseFiltre = entreprise.type_clienteles;
            break;
          case FiltreType.certification:
            entrepriseFiltre = entreprise.certifications;
            break;
        }
        if (
          !entrepriseFiltre.some((item) =>
            filtres.some((fil) => fil.id === item.id)
          )
        ) {
          return false;
        }
      }
      for (let [filtreType, filtres] of filtresRegroupements.filtresEntreprises
        .filtreEt) {
        let entrepriseFiltre;
        switch (filtreType) {
          case FiltreType.typeProduction:
            entrepriseFiltre = entreprise.type_productions;
            break;
        }
        if (
          !filtres.every((fil) =>
            entrepriseFiltre.some((item) => fil.id == item.id)
          )
        ) {
          return false;
        }
      }
      return true;
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(this.recherche.toLowerCase())
        )
      );
    },
    entrepriseContienRecherche(entreprise: Entreprise) {
      return (
        this.recherche == '' ||
        this.getIfStringMatchSearch(entreprise.nom) ||
        this.getIfStringMatchSearch(entreprise.adresse) ||
        (entreprise.ville &&
          this.getIfStringMatchSearch(entreprise.ville.nom)) ||
        this.getIfStringMatchSearch(entreprise.codePostal) ||
        this.getIfStringMatchSearch(entreprise.courriel) ||
        this.getIfStringMatchSearch(entreprise.facebook) ||
        this.getIfStringMatchSearch(entreprise.instagram) ||
        this.getIfStringMatchSearch(entreprise.siteWeb) ||
        this.getIfStringMatchSearch(entreprise.twitter) ||
        (entreprise.entreprise_produits !== undefined &&
          entreprise.entreprise_produits.some(
            (prod) =>
              this.getIfStringMatchSearch(prod.produit.nom) ||
              (prod.conservations !== undefined &&
                prod.conservations.some((item) =>
                  this.getIfStringMatchSearch(item.nom)
                )) ||
              (prod.specificites !== undefined &&
                prod.specificites.some((item) =>
                  this.getIfStringMatchSearch(item.nom)
                )) ||
              (prod.type_transaction !== undefined &&
                this.getIfStringMatchSearch(prod.type_transaction.nom))
          )) ||
        entreprise.type_clienteles.some((typeClientelle) =>
          this.getIfStringMatchSearch(typeClientelle.nom)
        ) ||
        entreprise.certifications.some((certification) =>
          this.getIfStringMatchSearch(certification.nom)
        ) ||
        entreprise.type_productions.some((typeProduction) =>
          this.getIfStringMatchSearch(typeProduction.nom)
        )
      );
    },
    faisRecherche() {
      this.chargeListeEntreprises();
      RequeteRechercheHandler.restartTimeout();
    },
    removeFiltre(filtre: Filtre) {
      RequeteRechercheHandler.restartTimeout();
      filtre.estActif = false;
      this.chargeListeEntreprises();
    },
    reinitialiseFiltres() {
      this.indexPage = 0;
      let nbFiltresActifs = 0;
      for (const filtre of this.filtres.values()) {
        if (filtre.estActif) {
          nbFiltresActifs++;
        }
        filtre.estActif = false;
      }
      if (nbFiltresActifs > 0) {
        RequeteRechercheHandler.restartTimeout();
      }
      this.chargeListeEntreprises();
    },
    sendRecherche() {
      const filtres = Array.of(...this.filtres.values())
        .filter((filtre) => filtre.estActif)
        .map((filtre) => {
          return {
            type: filtre.type,
            id: filtre.id,
            nom: filtre.nom,
          };
        });
      const rechercheBody = {
        recherche: this.recherche,
        filtres: filtres,
      };
      this.$store.dispatch('recherche/saveRecherche', rechercheBody);
    },
    rechercheFiltreItem(id: number, type: FiltreType) {
      if (id === undefined) {
        return undefined;
      } else {
        const key = `${type}-${id}`;
        return this.filtres.get(key);
      }
    },
    getListeEntreprisesWithFiltres(listeEntreprises: Array<Entreprise>) {
      return listeEntreprises.map((entreprise) => {
        entreprise.entreprise_produits.map((produit) => {
          // produit.pivot.conservation = this.rechercheFiltreItem(
          //   produit.pivot.conservation_id,
          //   FiltreType.conservation
          // );
          // produit.pivot.specificite = this.rechercheFiltreItem(
          //   produit.pivot.specificite_id,
          //   FiltreType.specificite
          // );
          produit.type_transaction = this.rechercheFiltreItem(
            produit.type_transaction_id,
            FiltreType.typeTransaction
          );
          produit.volume = this.rechercheFiltreItem(
            produit.volume_id,
            FiltreType.volume
          );
          return produit;
        });
        return entreprise;
      });
    },
  },
  mounted() {
    RequeteRechercheHandler.setRequestFunction(this.sendRecherche);
    this.recherche =
      this.$route.query.recherche === undefined
        ? ''
        : this.$route.query.recherche;
    this.indexPage =
      this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 0;

    this.$store
      .dispatch('entreprises/recupereListeEntreprises')
      .then((entreprises) => {
        EventSynchronisationHandler.on(
          EventSynchronisationName.filtresPret,
          () => {
            this.listeEntrepriseCache =
              this.getListeEntreprisesWithFiltres(entreprises);
            this.chargeListeEntreprises();
            this.entreprisesPretes = true;
          }
        );
      });
  },
});
