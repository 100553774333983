
import { defineComponent, inject, Ref } from 'vue';
import { Filtre } from '@/types/Filtre';
import { FiltreDTO } from '@/types/FiltreDTO';
import { FiltreType } from '@/types/FiltreType';
import RequeteRechercheHandler from '@/handlers/RequeteRechercheHandler';
import EventSynchronisationHandler from '@/handlers/EventSynchronisationHandler';
import { EventSynchronisationName } from '@/types/EventSynchronisationName';

export default defineComponent({
  name: 'FiltresEntreprises',
  events: ['filtreChangement', 'finChargementFiltres'],
  data() {
    return {
      filtres: {
        typesTransactions: {
          nom: 'Types de transactions',
          data: new Array<Filtre>(),
          show: true,
        },
        typeProductions: {
          nom: 'Types de production',
          data: new Array<Filtre>(),
          show: false,
        },
        typeClienteles: {
          nom: 'Types de clientèles',
          data: new Array<Filtre>(),
          show: false,
        },
        produits: {
          nom: 'Produits',
          data: new Array<Filtre>(),
          show: false,
        },
        volumes: {
          nom: 'Volumes',
          data: new Array<Filtre>(),
          show: false,
        },
        conservations: {
          nom: 'Type de conservation',
          data: new Array<Filtre>(),
          show: false,
        },
        specificites: {
          nom: 'Spécificité',
          data: new Array<Filtre>(),
          show: false,
        },
        certifications: {
          nom: 'Certification',
          data: new Array<Filtre>(),
          show: false,
        },
      },
    };
  },
  setup() {
    const filtresRef = inject('filtres') as Ref<Map<string, Filtre>>;
    return {
      filtresRef,
    };
  },
  methods: {
    hideFilterCategory(filtre) {
      for (let i = 0; i < filtre.data.length; i++) {
        if (filtre.data[i].entreprises_count !== 0) {
          return false;
        }
      }
      return true;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleShow(filtre: { show: boolean }) {
      filtre.show = !filtre.show;
    },
    onFiltreChange() {
      RequeteRechercheHandler.restartTimeout();
      this.$emit('filtreChangement');
    },
    getFiltreListeWithAddidionalParams(
      filtreListe: Array<FiltreDTO>,
      typeFiltre: FiltreType
    ): Array<Filtre> {
      return filtreListe.map((filtreItem) => {
        return {
          ...filtreItem,
          estActif: false,
          type: typeFiltre,
        };
      });
    },
    setFilters() {
      const {
        typeProductions,
        typeClienteles,
        produits,
        volumes,
        conservations,
        typeTransactions,
        specificites,
        certifications,
      } = this.$store.getters['filtres/tousFiltres'];
      const trueTypeProductions = this.getFiltreListeWithAddidionalParams(
        typeProductions,
        FiltreType.typeProduction
      );
      const trueTypeClienteles = this.getFiltreListeWithAddidionalParams(
        typeClienteles,
        FiltreType.typeClientelle
      );
      const trueProduits = this.getFiltreListeWithAddidionalParams(
        produits,
        FiltreType.produit
      );
      const trueVolume = this.getFiltreListeWithAddidionalParams(
        volumes,
        FiltreType.volume
      );
      const trueConservations = this.getFiltreListeWithAddidionalParams(
        conservations,
        FiltreType.conservation
      );
      const trueTypeTransactions = this.getFiltreListeWithAddidionalParams(
        typeTransactions,
        FiltreType.typeTransaction
      );
      const trueSpecificites = this.getFiltreListeWithAddidionalParams(
        specificites,
        FiltreType.specificite
      );
      const trueCertifications = this.getFiltreListeWithAddidionalParams(
        certifications,
        FiltreType.certification
      );
      this.filtres.typeProductions.data = trueTypeProductions;
      this.filtres.typeClienteles.data = trueTypeClienteles;
      this.filtres.produits.data = trueProduits;
      this.filtres.conservations.data = trueConservations;
      this.filtres.volumes.data = trueVolume;
      this.filtres.typesTransactions.data = trueTypeTransactions;
      this.filtres.specificites.data = trueSpecificites;
      this.filtres.certifications.data = trueCertifications;
      trueTypeProductions
        .concat(trueTypeClienteles)
        .concat(trueProduits)
        .concat(trueConservations)
        .concat(trueVolume)
        .concat(trueTypeTransactions)
        .concat(trueSpecificites)
        .concat(trueCertifications)
        .forEach((filtre) => {
          const filtreKey = filtre.type + '-' + filtre.id;
          this.filtresRef.set(filtreKey, filtre);
        });
      EventSynchronisationHandler.invoke(EventSynchronisationName.filtresPret);
    },
  },
  mounted() {
    Promise.all([
      this.$store.dispatch('filtres/fetchTypeProduction'),
      this.$store.dispatch('filtres/fetchTypeClientele'),
      this.$store.dispatch('filtres/fetchProduit'),
      this.$store.dispatch('filtres/fetchConservation'),
      this.$store.dispatch('filtres/fetchVolume'),
      this.$store.dispatch('filtres/fetchTypeTransaction'),
      this.$store.dispatch('filtres/fetchSpecificite'),
      this.$store.dispatch('filtres/fetchCertification'),
    ]).then(() => {
      this.setFilters();
    });
  },
});
