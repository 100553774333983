import { Commit } from 'vuex';
import axios from 'axios';

const entreprises = {
  namespaced: true,
  actions: {
    rechercheEntreprise({ commit }: { commit: Commit }, recherche: string) {
      // commit('rechercheEntreprise', filtre);
      // console.log(recherche);
    },
    recupereListeEntreprises({ commit }: { commit: Commit }) {
      // api/liste-entreprises
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/entreprises`;

        axios
          .get(url)
          .then(function (response) {
            // handle success
            resolve(response.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      });
    },
    // rapportEntreprises({ commit }: { commit: Commit }) {
    //   // api/liste-entreprises
    //   return new Promise((resolve, reject) => {
    //     const url = `${process.env.VUE_APP_BACKEND_API_URL}api/rapportEntreprises`;

    //     axios
    //       .get(url)
    //       .then(function (response) {
    //         // handle success
    //         resolve(response.data);
    //       })
    //       .catch(function (error) {
    //         // handle error
    //         console.log(error);
    //       });
    //   });
    // },
    recupereFiltres({ commit }: { commit: Commit }) {
      return new Promise((resolve, reject) => {
        const baseUrl = process.env.VUE_APP_BACKEND_API_URL;

        const one = baseUrl + 'api/types-produits';
        const two = baseUrl + 'api/types-production';
        const three = baseUrl + 'api/types-clientele';

        const requestOne = axios.get(one);
        const requestTwo = axios.get(two);
        const requestThree = axios.get(three);

        axios
          .all([requestOne, requestTwo, requestThree])
          .then(
            axios.spread((...responses) => {
              // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios

              const responseOne = responses[0];
              const responseTwo = responses[1];
              const responseThree = responses[2];

              const tableauReponses = [
                responseOne.data,
                responseTwo.data,
                responseThree.data,
              ];

              resolve(tableauReponses);
            })
          )
          .catch((errors) => {
            console.log(errors);
          });
      });
    },
  },
};

export default entreprises;
