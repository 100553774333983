import { Filtre } from './Filtre';
import { FiltreType } from './FiltreType';

export enum FiltresRegroupementsLogicType {
  OU,
  ET,
}
export enum FiltresRegroupementsTargetType {
  Entreprise,
  Produit,
}

export class FiltresRegroupements {
  public filtresEntreprises = {
    filtreOu: new Map<FiltreType, Array<Filtre>>(),
    filtreEt: new Map<FiltreType, Array<Filtre>>(),
  };
  public filtresProduits = {
    filtreOu: new Map<FiltreType, Array<Filtre>>(),
    filtreEt: new Map<FiltreType, Array<Filtre>>(),
  };
  private addFiltreProduit(
    type: FiltreType,
    filtre: Filtre,
    logicType: FiltresRegroupementsLogicType
  ) {
    if (logicType === FiltresRegroupementsLogicType.ET) {
      if (this.filtresProduits.filtreEt.has(type)) {
        this.filtresProduits.filtreEt.get(type).push(filtre);
      } else {
        this.filtresProduits.filtreEt.set(type, new Array<Filtre>(filtre));
      }
    } else {
      if (this.filtresProduits.filtreOu.has(type)) {
        this.filtresProduits.filtreOu.get(type).push(filtre);
      } else {
        this.filtresProduits.filtreOu.set(type, new Array<Filtre>(filtre));
      }
    }
  }
  private addFiltreEntreprise(
    type: FiltreType,
    filtre: Filtre,
    logicType: FiltresRegroupementsLogicType
  ) {
    if (logicType === FiltresRegroupementsLogicType.ET) {
      if (this.filtresEntreprises.filtreEt.has(type)) {
        this.filtresEntreprises.filtreEt.get(type).push(filtre);
      } else {
        this.filtresEntreprises.filtreEt.set(type, new Array<Filtre>(filtre));
      }
    } else {
      if (this.filtresEntreprises.filtreOu.has(type)) {
        this.filtresEntreprises.filtreOu.get(type).push(filtre);
      } else {
        this.filtresEntreprises.filtreOu.set(type, new Array<Filtre>(filtre));
      }
    }
  }

  public addFiltre(filtre: Filtre) {
    const filtreInfo = this.getInfoByFiltreType(filtre.type);
    if (filtreInfo.targetType === FiltresRegroupementsTargetType.Entreprise) {
      this.addFiltreEntreprise(filtre.type, filtre, filtreInfo.logic);
    } else {
      this.addFiltreProduit(filtre.type, filtre, filtreInfo.logic);
    }
  }

  private getInfoByFiltreType(type: FiltreType): {
    logic: FiltresRegroupementsLogicType;
    targetType: FiltresRegroupementsTargetType;
  } {
    switch (type) {
      case FiltreType.typeClientelle:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Entreprise,
        };
      case FiltreType.typeProduction:
        return {
          logic: FiltresRegroupementsLogicType.ET,
          targetType: FiltresRegroupementsTargetType.Entreprise,
        };
      case FiltreType.produit:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Produit,
        };
      case FiltreType.volume:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Produit,
        };
      case FiltreType.conservation:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Produit,
        };
      case FiltreType.typeTransaction:
        return {
          logic: FiltresRegroupementsLogicType.ET,
          targetType: FiltresRegroupementsTargetType.Produit,
        };
      case FiltreType.certification:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Entreprise,
        };
      case FiltreType.specificite:
        return {
          logic: FiltresRegroupementsLogicType.OU,
          targetType: FiltresRegroupementsTargetType.Produit,
        };
    }
  }
}
