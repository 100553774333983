
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Nav',
  methods: {
    loginLink(): string {
      return process.env.VUE_APP_BACKEND_API_URL + 'login';
    },
  },
});
