
import { defineComponent } from 'vue';
import Nav from '@/components/Nav.vue';
import ModalVideo from './ModalVideo.vue';

export default defineComponent({
  name: 'Header',
  components: {
    Nav,
    ModalVideo,
  },
  data() {
    return {
      showModal: false,
    };
  },
});
