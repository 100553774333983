import { EventSynchronisationName } from '@/types/EventSynchronisationName';

type FunctionToCallParameters = (...args) => void;

// eslint-disable-next-line
type FunctionToCallArgsType = Array<any>;

interface FunctionContainer {
  func: FunctionToCallParameters;
  args: FunctionToCallArgsType;
}

class EventSynchronisationHandlerClass {
  private events: Map<EventSynchronisationName, Array<FunctionContainer>> =
    new Map<EventSynchronisationName, Array<FunctionContainer>>();
  private eventsHappened = new Set<EventSynchronisationName>();
  public on(
    eventName: EventSynchronisationName,
    actionToDo: FunctionToCallParameters,
    ...params: FunctionToCallArgsType
  ) {
    const functionContainer = { func: actionToDo, args: params };
    if (this.events.has(eventName)) {
      (this.events.get(eventName) as Array<FunctionContainer>).push(
        functionContainer
      );
    } else {
      const newArray = new Array<FunctionContainer>();
      newArray.push(functionContainer);
      this.events.set(eventName, newArray);
    }
    if (this.eventsHappened.has(eventName)) {
      actionToDo(params);
    }
  }

  public invoke(eventName: EventSynchronisationName) {
    this.eventsHappened.add(eventName);
    if (this.events.has(eventName)) {
      this.events.get(eventName).forEach((functionContainer) => {
        functionContainer.func(functionContainer.args);
      });
    }
  }
}

const EventSynchronisationHandler = new EventSynchronisationHandlerClass();
export default EventSynchronisationHandler;
