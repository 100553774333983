export enum FiltreType {
  typeProduction = 'typeProduction',
  typeClientelle = 'typeClientelle',
  produit = 'produit',
  conservation = 'conservation',
  volume = 'volume',
  typeTransaction = 'typeTransaction',
  certification = 'certification',
  specificite = 'specificite',
}
