
import { defineComponent } from 'vue';
import { InscriptionData } from '@/types/Inscription/InscriptionData';
import { AxiosResponse } from 'axios';
import { InscriptionDTO } from '@/types/Inscription/InscriptionDTO';
import useVuelidate from '@vuelidate/core';
import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
} from '@vuelidate/validators';
import { Consentement } from '@/types/Inscription/Consentement';
export default defineComponent({
  name: 'PageInscription',
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    urlRules() {
      return helpers.regex(/[-_0-9a-zA-Z]+\.[0-9a-zA-Z]+$/);
    },
    urlMask() {
      return {
        mask: '!https://H*',
        tokens: { H: { pattern: /[0-9a-zA-Z_.-]+/ } },
      };
    },
  },
  data() {
    return {
      mrcs: new Array<InscriptionData>(),
      villes: new Array<InscriptionData>(),
      villesMrc: new Array<InscriptionData>(),
      consentements: new Array<Consentement>(),
      inscriptionData: {} as InscriptionDTO,
      requiredMessage: 'Champ requis.',
      requiredMessageCheckbox: 'Au moins un champ doit être coché',
      wrongFormat: 'Format incorrect. Format: A1A 1A1',
      wrongUrlFormat: 'Format incorrect.',
      serverError: false,
      serverMessage: '',
      errorCheckboxes: false,
    };
  },
  validations() {
    return {
      inscriptionData: {
        nom: { required, minLength: minLength(3), maxLength: maxLength(40) },
        prenom: { required, minLength: minLength(3), maxLength: maxLength(40) },
        email: { required, email, maxLength: maxLength(80) },
        nomEntreprise: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        emailEntreprise: { required, email, maxLength: maxLength(80) },
        adresseProd: { required, maxLength: maxLength(255) },
        mrc_id: { required },
        ville_id: { required },
        siteWeb: { maxLength: maxLength(255), url: this.urlRules },
        facebook: { maxLength: maxLength(255), url: this.urlRules },
        instagram: { maxLength: maxLength(255), url: this.urlRules },
        twitter: { maxLength: maxLength(255), url: this.urlRules },
        codePostal: {
          required,
          codePostal: helpers.regex(
            /^[a-zA-Z]{1}\d{1}[a-zA-Z]{1}\s\d{1}[a-zA-Z]{1}\d{1}$/
          ),
        },
      },
    };
  },
  methods: {
    openPopup() {
      document.getElementById('popup').style.display = 'block';
    },
    closePopup() {
      document.getElementById('popup').style.display = 'none';
      this.$router.push({ name: 'PageEntreprises' });
    },
    submitform() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.register();
    },
    register() {
      this.inscriptionData.consentements = this.consentements.filter(
        (item) => item.selected
      );
      this.errorCheckboxes = this.inscriptionData.consentements.length <= 0;
      if (!this.errorCheckboxes) {
        this.$store
          .dispatch('inscription/demandeInscription', this.inscriptionData)
          .then(() => {
            this.openPopup();
          })
          .catch((message) => {
            this.serverError = true;
            this.$toast.error(message);
            this.serverMessage = message;
          });
      }
    },
    loginLink(): string {
      return process.env.VUE_APP_BACKEND_API_URL + 'login';
    },
    onChange() {
      this.villesMrc = this.villes.filter(
        (item) => item.mrc_id === this.inscriptionData.mrc_id
      );
    },
  },
  mounted() {
    this.$store
      .dispatch('inscription/getMrcs')
      .then((response: AxiosResponse) => {
        this.mrcs = response;
      });
    this.$store
      .dispatch('inscription/getVilles')
      .then((response: Array<InscriptionData>) => {
        this.villes = response;
      });
    this.$store
      .dispatch('inscription/getConsentements')
      .then((response: Array<InscriptionData>) => {
        this.consentements = response.map((item) => {
          return { ...item, selected: false };
        });
      });
  },
});
