import { createStore } from 'vuex';
import entreprises from '@/store/entreprises';
import filtres from '@/store/filtres';
import inscription from '@/store/inscription';
import recherche from '@/store/recherche';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    entreprises,
    filtres,
    inscription,
    recherche,
  },
});
