
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ModalEntreprise',
  data() {
    return {
      data: {},
      entreprise_produits: {},
      entreprise_certifications: {},
    };
  },
  methods: {
    updateData(entreprise) {
      this.data = entreprise;
      this.entreprise_produits = entreprise.entreprise_produits;
      this.entreprise_certifications = entreprise.certifications;
    },
  },
});
