
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterPartner',
  data() {
    return {
      imagesPartners: [],
    };
  },
  methods: {
    importAll(data) {
      data.keys().map((item) => {
        let image = {
          url: '',
          alt: '',
        };
        image.alt = item.replace('./', '').replace('.jpg', '');
        image.url = require('../assets/img/partners/' + item.replace('./', ''));
        this.imagesPartners.push(image);
      });
    },
  },
  mounted() {
    this.importAll(
      require.context('../assets/img/partners/', false, /\.(png|jpe?g|svg)$/)
    );
  },
});
