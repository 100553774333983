import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af8946a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filtres" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "chevron-up pr25"
}
const _hoisted_4 = {
  key: 1,
  class: "chevron-down pr25"
}
const _hoisted_5 = {
  key: 0,
  class: "conteneur-cases"
}
const _hoisted_6 = {
  key: 0,
  class: "conteneur-une-case-un-libelle"
}
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { class: "libelle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtres, (filtre, key) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["separateur", {
          hide: filtre.data.length <= 0 || _ctx.hideFilterCategory(filtre),
        }]),
          key: key
        }, [
          _createElementVNode("strong", {
            class: "titre-principal",
            onClick: ($event: any) => (_ctx.toggleShow(filtre))
          }, [
            _createElementVNode("h2", null, _toDisplayString(filtre.nom), 1),
            (filtre.show)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3))
              : _createCommentVNode("", true),
            (!filtre.show)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2),
          (filtre.show)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filtre.data, (filtreItem) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "case",
                    key: filtreItem.id
                  }, [
                    (filtreItem.entreprises_count !== 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            "onUpdate:modelValue": ($event: any) => ((filtreItem.estActif) = $event),
                            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFiltreChange && _ctx.onFiltreChange(...args)))
                          }, null, 40, _hoisted_7), [
                            [_vModelCheckbox, filtreItem.estActif]
                          ]),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.capitalizeFirstLetter(filtreItem.nom)) + " (" + _toDisplayString(filtreItem.entreprises_count) + ") ", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ]))
}